import React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { Typography } from '@mui/material';
import List from '@mui/material/List';

export default function FibrifySearchBarSection({
  children,
  title,
  route,
  totalItems,
  onMoreClick,
  search,
}) {
  return (
    <List
      subheader={
        <ListSubheader
          component="div"
          disableSticky
          sx={{ lineHeight: '32px' }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <span>
              <span>{title}</span>
              {totalItems === 0 ? (
                <Typography
                  variang="caption"
                  color="secondary"
                  sx={{ ml: 2, fontSize: '14px' }}
                  component="span"
                >
                  nothing found
                </Typography>
              ) : (
                ''
              )}
            </span>
            <Link
              component={RouterLink}
              to={[route, `search=${search}`].join('?')}
              onClick={onMoreClick}
            >
              more
            </Link>
          </Stack>
        </ListSubheader>
      }
    >
      {children}
    </List>
  );
}
